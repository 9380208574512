import { useTranslations } from "next-intl";

export function useIntlRealestateStatus() {
  const translation = useTranslations("formRealestateFilters");

  const CRealEstateStatus = [
    { label: translation("underConstruction"), value: 1 },
    { label: translation("built"), value: 2 },
  ];

  return CRealEstateStatus;
}

export const CRealEstateStatus = [
  { label: "En construcción", value: 1 },
  { label: "Construidas", value: 2 },
];
