import { RealestateEndpoints } from "shared/models/endpoits/realestate.endpoint";
import { IBaseFilter } from "shared/models/interfaces/base-filter-list";
import {
  IRealestateResponse,
  IRealestateResponseDetails,
} from "shared/models/interfaces/response/realestate.interface";
import { getRequest } from "./base-services.request";

export const getRealestate = async (
  filters: IRealestateFilters
): Promise<IRealestateResponse> => {
  return getRequest(RealestateEndpoints.RealestateList, filters).then(
    (response) => response?.data
  );
};

export const getRealestateLog = async (id: number | string) => {
  return getRequest(RealestateEndpoints.RealEstateLog(id)).then(res => {
    return res.data
  }).catch(err => {
    return err.response
  })
}

export const getRealestateDetails = async (
  id: number,
  filters?: {
    withAgencyReferer?: number | null |undefined;
    withAgentReferer?: number | null |undefined;
  }
): Promise<IRealestateResponseDetails | any> => {
  return getRequest(RealestateEndpoints.RealestateDetail(id), filters).then(
    (response) => response?.data
  ).catch((error) => error.response);
};

export interface IRealestateFilters extends IBaseFilter {
  agent?: number; //id user
  business?: TBusiness; // type of business
  sector?: number; // id sector (one or more sectors: 1,2,3)
  price?: string; // >=1200,rd || between = "10...100,rd" or ">=1000,rd"
  landSize?: string; //>=10
  search?: string; // opcional q
  bathrooms?: number; // number
  floorLevels?: number; // number
  parkings?: number; // number
  bedrooms?: number; // number
  feature?: number; // number
  condition?: string; // unused,used (multiple values comman separate)
  type?: string; // property type
  exclusives?: boolean; //return property exclusives
  collection?: boolean; // return property collections
  onlyUnits?: boolean;
  similarCode?: number; // return similar properties
}

type TBusiness = "sale" | "lease";
