import { Grid, IconButton } from "@mui/material";
import { useField } from "formik";
import React from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import styled from "@emotion/styled";

interface IButtonTypesField {
  name: string;
  label: string;
}
function ButtonTypesField(props: IButtonTypesField) {
  const [field, meta, helpers] = useField({ name: props.name } as any);

  const add = () => {
    helpers.setValue(+field.value + 1);
  };
  const reduce = () => {
    helpers.setValue(+field.value - 1);
  };
  
  const getVALUE = () => {
    if (
      field.value === null ||
      field.value === undefined ||
      field.value === "" ||
      field.value &&
      field.value.length === 0
    ) {
      return 0;
    } else {
      return field.value;
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Label>{props.label}</Label>
      <Grid>
        <ButtonToggle
          color="primary"
          disabled={field.value == 0 || field.value == null}
          onClick={reduce}
        >
          <RemoveOutlinedIcon />
        </ButtonToggle>
        <LabelValue>{getVALUE()}</LabelValue>
        <ButtonToggle color="primary" onClick={add}>
          <AddOutlinedIcon />
        </ButtonToggle>
      </Grid>
    </Grid>
  );
}

export default ButtonTypesField;

const ButtonToggle = styled(IconButton)`
  border: 3px solid var(--color-blue-main);
  padding: 3px;
  margin: 2px 8px;
  &.Mui-disabled {
    border: 3px solid gray;
  }
`;
const Label = styled.label`
  font-size: 15px;
  font-weight: 600;
  color: var(--color-blue-main);
`;

const LabelValue = styled.span`
  font-size: 15px;
  font-weight: 600;
  margin: 0px 15px;
  display: inline-block;
  width:10px;
  color: var(--color-blue-main);
`;
