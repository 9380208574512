import { SectorsEndpoints } from "shared/models/endpoits/sectors.endpoint";
import { IBaseFilter } from "shared/models/interfaces/base-filter-list";
import { SectorForSelect } from "shared/models/interfaces/response/sector.interface";
import { getRequest } from "./base-services.request";

export interface ISectorsListFilters extends IBaseFilter {
  q?: string;
  provinceId?: string;
  limit?: number;
  cityId?:number;
}
export const getSector = (filters: ISectorsListFilters): Promise<SectorForSelect> => {
  return getRequest(SectorsEndpoints.SectorsList, filters).then(
    (response) => response?.data
  );
};
