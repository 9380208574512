import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { getRealestateDetails } from "api/realestate.request";
import { useMutation } from "@tanstack/react-query";
import { CPagesPath } from "shared/models/const/pages-path";
import { ModalMessage } from "shared/components/message-modal";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useTranslations } from "next-intl";
import { Form, Formik, FormikConfig } from "formik";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import { NumberInput } from "shared/components/mask-components";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "shared/components/text-field";
import { css } from "@emotion/react";
import AccordionBase from "shared/components/accordion";

let schema = yup.object().shape({
  code: yup.number().nullable(),
});

const FormCodeSearch = () => {
  const router = useRouter();
  const [open, setOpen] = useState<boolean>(false);
  const isCollection = router.asPath.includes(CPagesPath.es.collection);
  const translationNotFound = useTranslations("buy-sell-filter.notFound");
  const translations = useTranslations("formRealestateFilters");

  const propertyDetailRedirect = isCollection
    ? CPagesPath.es.collection_property_detail
    : CPagesPath.es.property_detail;

  const handleToggleModal = () => {
    setOpen(!open);
  };

  const getRealestateRequest = useMutation(
    ["detail-realstate"],
    (realestateId: number) => {
      return getRealestateDetails(realestateId);
    }
  );

  const form: FormikConfig<any> = {
    initialValues: {
      code: null,
    },
    onSubmit: (e) => {
      getRealestateRequest
        .mutateAsync(e.code)
        .then((r) => {
          router.push({
            pathname: propertyDetailRedirect(r.data),
            query: { ...router.query },
          });
        })
        .catch(() => handleToggleModal());
    },
    validationSchema: schema,
  };

  return (
    <AccordionBase label={<TextAccordion >Buscar por código</TextAccordion>} id="form-code">
      <Formik {...form}>
        {(props) => (
          <Grid
            component={Form}
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginTop: 2, marginBottom: 3 }}
          >
            <Grid item xs={8}>
              <TextFieldCode
                inputComponent={NumberInput}
                placeholder={translations("code")}
                name="code"
                id="code"
              ></TextFieldCode>
            </Grid>
            <ContainerSearchButtonIcon item xs={4} sx={{mt:1}}>
              <ButtonSearch
                loading={getRealestateRequest.isLoading}
                variant="contained"
                type="button"
                onClick={() => props.handleSubmit()}
              >
                <SearchButtonIcon />
              </ButtonSearch>
            </ContainerSearchButtonIcon>
            <ModalMessage
              title={translationNotFound("title")}
              body={translationNotFound("body")}
              variant="error"
              open={open}
              handleClose={handleToggleModal}
            />
          </Grid>
        )}
      </Formik>
    </AccordionBase>
  );
};

export default FormCodeSearch;

const TextAccordion = styled.a`
  text-decoration:underline;
`;


const SearchButtonIcon = styled(SearchIcon)`
  color: white;
  font-size: 26px;
`;

const ButtonSearch = styled(LoadingButton)`
  color: white;
  font-size: 26px;
  background: var(--color-blue-main);
  width: 80%;
  height: 55px;
  border-radius: 50px;
`;

const ContainerSearchButtonIcon = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextFieldCustomShared = css`
  background-color: white;
  padding: 20px;
  border-radius: 30px;
  border: 0px solid transparent;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  & input::placeholder {
    color: gray;
  }
  &* {
    outline: none;
  }
`;

const TextFieldCode = styled(TextField)`
  ${TextFieldCustomShared}
`;
