
import React from "react";
import styled from "@emotion/styled";
export interface MessageResultsInterface {
    message:string;
    modalComponent?: JSX.Element;
}
export const MessageResults = (props: MessageResultsInterface) => {
  return (
    <MessageContent>
      <span>{props.message} {props?.modalComponent && React.cloneElement(props.modalComponent, {})}</span>
    </MessageContent>
  );
};

const MessageContent = styled.div`
  height: calc(100vh - 375px);
  display: flex;
  justify-content: center;
  align-items: center;
  & span {
    font-size: 19px;
    font-weight: 600;
    color: var(--color-gray-01);
    max-width: 800px;
    text-align: center;
  }
`;