import styled from "@emotion/styled";
import React from "react";
import { FormControlLabel, FormGroup, Grid } from "@mui/material";
import { Checkbox, CheckboxProps } from "@mui/material";
import { useField } from "formik";
import { remove } from "lodash";

interface IformGroup {
  options: {
    label: string;
    value: any | string | number;
    textWrap?: boolean;
  }[];
  name: string;
  checkBoxProps?: CheckboxProps;
  row?: boolean;
  column: number;
  handleChange?: () => void;
  value?: string;
  multiple?: boolean;
}

const CheckBoxGroupV2 = (props: IformGroup) => {
  const { multiple = true } = props;
  const [field, meta, helpers] = useField({ name: props.name });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      if (!multiple) {
        helpers.setValue([event.target.value + ""]);
        return;
      }
      helpers.setValue([event.target.value + "", ...field.value]);
    } else {
      helpers.setValue(
        remove(field.value, (n: string) => n != event.target.value)
      );
    }
  };

  return (
    <FormGroup row={props.row ?? true}>
      <ContentGroup
        css={{
          gridTemplateColumns: "repeat(" + props.column + ", 1fr)",
        }}
      >
        {props.options.map((opt, i) => (
          <ControlLabel
            style={!opt.textWrap ? {} : { whiteSpace: "nowrap" }}
            name={props.name}
            key={i}
            control={
              <ControlCheckBox
                name={props.name}
                {...props.checkBoxProps}
                onChange={props.handleChange ?? handleChange}
                value={opt.value}
                checked={field.value?.includes(opt.value + "")}
              />
            }
            label={opt.label}
          />
        ))}
      </ContentGroup>
    </FormGroup>
  );
};

export default CheckBoxGroupV2;

const ControlCheckBox = styled(Checkbox)`
  ${({ name }) =>
    name === "improvements"
      ? `
  &.MuiButtonBase-root {
    padding: 2px;
    padding-right: 0;
  }
  `
      : null}
`;

const ControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-weight: bold;
    font-size: ${({ name }) => (name === "improvements" ? "12px" : "15px")};
    color: var(--color-blue-main);
    font-family: var(--font-family);
    text-transform: capitalize;
    white-space: nowrap;
  }

  &.MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const ContentGroup = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 5px;
`;
