import React from "react";
import { useField } from "formik";

import AutoCompleteField from "./AutoCompleteField";

function FormikAutocomplete(props: any) {
  const { name, ...rest } = props;
  const [field, meta, helpers] = useField({ name });

  const onChange = (e: any, s: any) => {
    helpers.setValue(s);
  };

  return (
    <AutoCompleteField {...rest} value={field.value} onChange={onChange} />
  );
}

export default FormikAutocomplete;
