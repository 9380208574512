import FilterAutoComplete from "shared/components/advance-filter-dialog/form-real-estate/mobile-form/auto-complete-field";
import React, { useState } from "react";
import { useTranslations } from "next-intl";
import useCities from "custom-hooks/use-cities.hook";
import sortCities from "@helpers/cities.helper";

function CitiesField() {
  const [search, onSearch] = useState("");
  const t = useTranslations("buy-sell-filter");
  const { data } = useCities();
  const items = data ? sortCities(data) : [];

  return (
    <FilterAutoComplete
      data={items}
      id="locations"
      name="locations"
      multiple={false}
      showMessageMin={true}
      label={t("locationMobile")}
      onInput={(e) => onSearch(e)}
    ></FilterAutoComplete>
  );
}

export default CitiesField;
