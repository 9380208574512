import FilterAutoComplete from 'shared/components/advance-filter-dialog/form-real-estate/mobile-form/auto-complete-field'
import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useFormikContext } from 'formik'
import { getSector } from 'api/sector.request'

const SectorsRequest = (filters: any) => {
  return getSector({
    q: filters.search == "" ? "" : filters.search,
    limit: 10,
    cityId: filters.citieID,
  });
};

function SectorsField() {
  const c: any = useFormikContext();
  const [search, onSearch] = useState("");
  const citieID = (c?.values?.locations[0]?.id as any) ?? null;
  const { mutate, data } = useMutation(["sectorsList"], SectorsRequest);

  useEffect(() => {
    if (search?.length > 2)
      mutate({
        search,
        citieID,
      });
  }, [search]);

  const items = search?.length > 2 ? adapterSectors(data?.data ?? []) : [];

  return (
    <FilterAutoComplete
      id="sector"
      data={items}
      name="sector"
      label="Sector"
      multiple={true}
      showMessageMin={true}
      onInput={(e) => onSearch(e)}
      disabled={citieID ? false : true}
    />
  );
}

export default SectorsField;

const adapterSectors = (data: any[]) =>
  data.map((x) => ({ description: x.description, id: x.id }));
