import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useIntlImprovements } from "shared/models/const/improvements.conts";

import CheckBoxGroupV2 from "shared/components/checkbox-group-v2";
import AccordionBase from "../accordion";
import { useTranslations } from "next-intl";
interface IImprovementsList {
  columns: number;
}
function ImprovementsList(props: IImprovementsList) {
  const CImprovements = useIntlImprovements();
  const canSee = CImprovements.slice(0, 18);
  const hidden = CImprovements.slice(18);
  const [accordion, isActiveAccordion] = useState(false);
  const translation = useTranslations("formRealestateFilters");

  const ref = useRef()


  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} sx={{ m: "auto", mt: 4 }}>
        <div hidden={accordion}>
          <CheckBoxGroupV2
            column={props.columns}
            options={canSee}
            name="improvements"
            checkBoxProps={{
              icon: <RadioButtonUncheckedIcon sx={{ color: "gray" }} />,
              checkedIcon: <RadioButtonCheckedIcon sx={{ color: "#39b44a" }} />,
            }}
          />
        </div>

        <AccordionBase
          id="impro"
          label={!accordion ? translation("seeMore") : translation("seeLess")}
          isActiveAccordion={isActiveAccordion}
        >
          <CheckBoxGroupV2
            column={props.columns}
            options={ CImprovements }
            name="improvements"
            checkBoxProps={{
              icon: <RadioButtonUncheckedIcon sx={{ color: "gray" }} />,
              checkedIcon: <RadioButtonCheckedIcon sx={{ color: "#39b44a" }} />,
            }}
          />
        </AccordionBase>
      </Grid>
    </Grid>
  );
}

export default ImprovementsList;
