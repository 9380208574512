import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useWidth } from "custom-hooks/use-width.hook";
import { useTranslations } from "next-intl";
import * as React from "react";
import Button from "shared/components/Button";
import SliderTransition from "shared/components/slide-transition";
import { DesktopForm } from "./form-real-estate/desktop-form";
import { MobileForm } from "./form-real-estate/mobile-form";

interface IAdvanceFilterDialog {
  handleReset: () => void;
  handleSubmit: () => void;
  children: JSX.Element;
  refButton?:any
}

export default function AdvanceFilterDialog(props: IAdvanceFilterDialog) {
  const [open, setOpen] = React.useState(false)
  const width = useWidth();
  const translation = useTranslations("formRealestateFilters");
  const isMobile = ["xs", "sm"].includes(width);

  const handleToggleModal = () => {
    setOpen(!open);
  };

  const DialogHeader = (props: any) => (
    <DialogTitle css={stylesDialogHeader.root} fontSize={12} align="right">
      <Button
        variant="text"
        colortext={"--color-gray-02"}
        onClick={() => props.onClose()}
      >
        {translation("close")}
      </Button>
    </DialogTitle>
  );

  const { handleReset, handleSubmit } = props;
  let configProp: DialogProps = {
    open: open,
    TransitionComponent: SliderTransition,
    onClose: handleToggleModal,
    keepMounted: false,
    fullWidth: true,
    fullScreen: isMobile,
    maxWidth: "sm",
  };

  const formConfig = {
    handleReset,
    handleSubmit,
    handleToggleModal,
  };

  return (
    <>
      {React.cloneElement(props.children, {
        onClick: handleToggleModal,
        ref: props?.refButton ?? null
      })}
      <DialogCustom {...configProp}>
        <DialogHeader {...configProp}></DialogHeader>
        <DialogContent>
          {isMobile ? (
            <MobileForm {...formConfig} />
          ) : (
            <DesktopForm {...formConfig} />
          )}
        </DialogContent>
      </DialogCustom>
    </>
  );
}



const DialogCustom = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: "#EFEFF1",
    borderRadius: "20px",
  },
}));

const stylesDialogHeader = {
  root: css({
    textAlign: "right",
    fontSize: 13,
    color: "var(--color-gray-01)",
    cursor: "pointer",
  }),
};
