import styled from '@emotion/styled'
import MuiButton from '@mui/material/Button'
import React from 'react'
import SimpleAccordion from 'shared/components/accordion'
import AccordionBase from 'shared/components/accordion'
import autoCompleteField from 'shared/components/auto-complete-field'
import Button from 'shared/components/Button'
import RadioButtonGroup from 'shared/components/buttons-radio-group'
import ImprovementsList from 'shared/components/improvements-list'
import TextField from 'shared/components/text-field'
import { Divider, Grid } from '@mui/material'
import { NumberInput } from 'shared/components/mask-components'
import { useIntlBusinessTypes } from 'shared/models/const/businessTypes'
import { useIntlRealestateStatus } from 'shared/models/const/real-estate-status'
import { useWidth } from 'custom-hooks/use-width.hook'
import { useTranslations } from 'next-intl'
import { useIntlPropertyTypes } from 'shared/models/const/property-types'
import { useFormikContext } from 'formik'

import { FormRealEstateFormik } from '../util/form.util'
import CheckBoxGroupV2 from 'shared/components/checkbox-group-v2'
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

interface IDesktopForm {
  handleSubmit: () => void;
  handleReset: () => void;
  handleToggleModal: () => void;
}

export const DesktopForm = (props: IDesktopForm) => {
  const width = useWidth();
  const isMobile = ["xs", "sm"].includes(width);
  const translations = useTranslations("formRealestateFilters");
  const itemsStatus = useIntlRealestateStatus();
  const itemsBusiness = useIntlBusinessTypes();
  const CPropertyTypes = useIntlPropertyTypes();
  const ctxF = useFormikContext();


  const handleSubmit = () => {
    props.handleToggleModal();
    setTimeout(()=>{
      props.handleSubmit();
    }, 500)
  };

  const handleReset = () => {
    props.handleToggleModal()
    setTimeout(()=>{
      ctxF.setValues({
        ...FormRealEstateFormik.initialValues,
        bathrooms: null,
        bedrooms: null,
        floorLevels: null,
        parkings: null,
      });
      props.handleReset();
    }, 500)
  };

  return (
    <div className="p-15">
      <Grid container item xs={12} spacing={1} className="m-t-5 m-b-20">
        {isMobile && (
          <Grid item xs={12}>
            <StyledCustomAutoComp
              name="typeProperty"
              limitTags={1}
              multiple={true}
              getOptionLabel={(option: any) => option?.name}
              placeholder={translations("typePlaceholder")}
              options={CPropertyTypes}
              className="typeProperty-autocomplete"
            ></StyledCustomAutoComp>
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <CheckBoxGroupV2
        column={3}
        options={[
          {
            label: translations("underConstruction"),
            value: false,
          },
          {
            label: translations("built"),
            value: true,
          },
        ]}
        name="onlyUnits"
        checkBoxProps={{
          icon: <RadioButtonUncheckedIcon sx={{ color: "gray" }} />,
          checkedIcon: <RadioButtonCheckedIcon sx={{ color: "#39b44a" }} />,
        }}
      />
      
      <div style={{ marginTop: "15px" }}>
        <CheckBoxGroupV2
          column={3}
          options={[
            {
              label: translations("furnished"),
              value: 1,
            },
            {
              label: translations("whiteLine"),
              value: 2,
            },
          ]}
          multiple={false}
          name="extraBusinessTypes"
          checkBoxProps={{
            icon: <RadioButtonUncheckedIcon sx={{ color: "gray" }} />,
            checkedIcon: <RadioButtonCheckedIcon sx={{ color: "#39b44a" }} />,
          }}
        />
      </div>

      <Grid container spacing={3} className="p-t-15">
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TextField
            placeholder={translations("baths")}
            name="bathrooms"
            inputComponent={NumberInput}
          ></TextField>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TextField
            placeholder={translations("parkings")}
            name="parkings"
            inputComponent={NumberInput}
          ></TextField>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TextField
            placeholder={translations("level")}
            inputComponent={NumberInput}
            name="floorLevels"
          ></TextField>
        </Grid>
      </Grid>

      <Grid item container style={{ width: "100%", margin: "auto" }}>
        <ImprovementsList columns={3} />
      </Grid>

      <Grid
        container
        justifyContent="space-around"
        className="p-v-15 fixed-bottom b-0"
      >
        <DeleteButton variant="text" onClick={handleReset}>
          {translations("delete")}
        </DeleteButton>

        <Button
          type="button"
          onClick={handleSubmit}
          fs="12px"
          bg="--color-secondary"
          thin_button={true}
        >
          {translations("apply")}
        </Button>
      </Grid>
    </div>
  );
};

const DeleteButton = styled(MuiButton)`
  text-decoration: underline;
  font-weight: 700;
  font-size: 11px;
  padding-top: 20px;
  @media screen and (max-width: 475px) {
    margin-left: 0;
    margin-top: 15px;
  }
`;

const StyledRadioGroup = styled(RadioButtonGroup)`
  & .MuiFormControlLabel-root {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledCustomAutoComp = styled(autoCompleteField)`
  fieldset {
    border-radius: 50px;
  }
  input::placeholder {
    color: var(--color-blue-main);
    opacity: 1;
    font-weight: 600;
    font-size: 14px;
    padding-left: 10px;
  }
`;

// remove before tree weeks

// const styledAmount = {
//   xl: 4,
//   lg: 4,
//   md: 4,
//   sm: 6,
//   xs: 12,
//   sx: { marginTop: "26px" },
// };

// {
//   /* <Grid container sx={{ paddingY: 2 }} alignItems={"center"} spacing={1}>
//         <Grid container item xs={4}>
//           <LabelField label={translations("currency")} />
//           <Grid item xs={12} className="p-t-5 p-l-5">
//             <RadioButtonGroup name="currencyType" items={CCurrencyTypes} />
//           </Grid>
//         </Grid>

//         <Grid item {...styledAmount}>
//           <TextField
//             placeholder={translations("minimum")}
//             inputComponent={MoneyInput}
//             name="amountMin"
//           ></TextField>
//         </Grid>

//         <Grid item {...styledAmount}>
//           <TextField
//             inputComponent={MoneyInput}
//             placeholder={translations("maximum")}
//             name="amountMax"
//           ></TextField>
//         </Grid>
//       </Grid> */
// }

// {
//   /* <Divider />
//       <Grid
//         container
//         alignItems="center"
//         sx={{ paddingTop: "15px", paddingBottom: "25px" }}
//       >
//         <LabelField label="Quiero" />
//         <Grid item className="p-t-5 p-l-5">
//           <StyledRadioGroup name="businessTypes" items={itemsBusiness} />
//         </Grid>
//       </Grid>

//       <Divider /> */
// }

// {
//   /* <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
//           <TextField
//             placeholder={translations("bedrooms")}
//             name="bedrooms"
//             inputComponent={NumberInput}
//           ></TextField>
//         </Grid> */
// }
