import { css } from "@emotion/css";
import {
  Autocomplete,
  CircularProgress,
  debounce,
  Paper,
  TextField,
} from "@mui/material";
import { useCallback } from "react";
import LabelField from "../label-field";
import React from "react";

interface IAutoCompleteField {
  label?: string;
  options: any[];
  positionLabel?: "center" | "right" | "left";
  multiple?: boolean;
  placeholder?: string;
  className?: string;
  getOptionLabel?: any;
  groupBy?: any;
  limitTags?: number;
  disableClearable?: boolean;
  freeSolo?: boolean;
  onInputChange?: any;
  loading?: boolean;
  disableListWrap?: boolean;
  renderOption?: any;
  noOptionsText?: string;
  value: any;
  onChange: any;
  name?: string;
  INPUT_DELAY?: number;
  id?: string;
  defaultValue?:any

}

function AutoCompleteField(props: IAutoCompleteField) {
  // const INPUT_DELAY = props?.INPUT_DELAY ?? 500;
  const INPUT_DELAY = 0;
  const { label, options, onInputChange, value, onChange, ...rest } = props;

  const inputChangeHandler = (event: any, inputValue: any, reason: any) => {
    if (props.onInputChange) {
      props.onInputChange(event, inputValue, reason);
    }
  };

  const debounceHandler = useCallback(
    debounce((event, inputValue, reason) => {
      inputChangeHandler(event, inputValue, reason);
    }, INPUT_DELAY),
    []
  );

  const handleOnInputChange = useCallback(
    (event: any, inputValue: any, reason: any) => {
      event?.persist();
      debounceHandler(event, inputValue, reason);
    },
    []
  );

  const FieldComponent = (params: any) => (
    <TextField
      {...params}
      {...rest}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {props.loading ? (
              <CircularProgress color="primary" size={20} />
            ) : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
    />
  );

  return (
    <>
      <LabelField label={props.label} positionLabel={props.positionLabel} />
      <Autocomplete
        disabledItemsFocusable={true}
        openOnFocus={true}
        options={options}
        renderInput={FieldComponent}
        onInputChange={handleOnInputChange}
        PaperComponent={CustomPaper}
        onChange={onChange}
        value={value}
        includeInputInList={true}
        disableClearable={true}
        loadingText={"Cargando..."}
        noOptionsText={"Sin resultados"}
        getOptionDisabled={(option) => {
          return value.map((x: any) => x.id).includes(option.id);
        }}
        {...rest}

      />
    </>
  );
}

const CustomPaper = (props: any) => {
  return (
    <Paper
      {...props}
      className={css({
        fontSize: "14px",
        fontFamily: "var(--font-family)",
        textTransform: "uppercase",
        fontWeight: "500",
        "& .MuiAutocomplete-option": {
          color: "#989694",
          fontWeight: "500",
          ":hover": {
            color: "white",
            opacity: 1,
            background: "var(--color-blue-main) !important",
          },
        },
        "& .MuiAutocomplete-groupLabel": {
          color: "var(--color-blue-main)",
          fontWeight: "800",
        },
      })}
    />
  );
};
export default AutoCompleteField;
