import { City } from "@interfaces/response/cities.interface";
import { useQuery } from "@tanstack/react-query";
import { ICitiesListFilters, getCities } from "api/cities.request";
import { debounce } from "lodash";
import { useDebounce } from "usehooks-ts";

export default function useCities(filters: ICitiesListFilters = { q: "" }, debounce: number = 0) {
  const e = useDebounce(filters, debounce);
  return useQuery(['citiesList', e], () => getCities(e),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      select: (response) => [{
        id: 1,
        description: "DISTRITO NACIONAL",
      } as City, ...response.data],
    })


}