export const FormRealEstateFormik = {
  initialValues: {
    amountMax: "",
    amountMin: "",
    bathrooms: 0,
    bedrooms: 0,
    floorLevels: 0,
    parkings: 0,
    currencyType: "us",
    // propertyStatus: ["1"],
    realEstateType: "",
    search: "",
    locations: [],
    typeProperty: [],
    improvements: [],
  },
};
