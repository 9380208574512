import { parseRealestateFiltersToUrl } from "shared/models/functions/realestate-filters-helper";

export const FormConfig = {
  initialValues: {
    city: [],
    sector: [],
    typeProperty: [],
    locations: [],
    amountMax: null,
    amountMin: null,
    bathrooms: null,
    bedrooms: [],
    currencyType: "us",
    floorLevels: null,
    parkings: null,
    onlyUnits: [],
    // propertyStatus: ["1"],
    realEstateType: null,
    search: "",
    businessTypes: "sale",
    improvements: [],
    price: "",
    extraBusinessTypes:'',
  },
};

export const makeFilterObject = (value: any) => {
  let filters = parseRealestateFiltersToUrl(value);
  return filters;
};
