import { useField } from "formik";
import React from "react";
import DefaultSwitchLabels from "./default-switch-labels";
interface ISwitchLabels {
  name: string;
  options: { label: string; value: any }[];
}

export default function FormikSwitchLabels(props: ISwitchLabels) {
  const { name, options } = props;
  const [field, meta, helpers] = useField({ name } as any);

  const handleClick = (value: any) => {
    helpers.setValue(value);
  };

  return (
    <DefaultSwitchLabels
      onChange={handleClick}
      options={options}
      value={field.value}
    />
  );
}
