import SearchIcon from '@mui/icons-material/Search'
import React, { useCallback } from 'react'
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled'
import { styled } from '@mui/material/styles'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import { Chip, Divider } from '@mui/material'
import { useField, useFormikContext } from 'formik'
import { debounce } from 'lodash'

interface values {
  description: string;
  id: number;
}
interface IFilterAutoComplete {
  id?: string;
  name: string;
  data: values[];
  multiple?: boolean;
  label: string;
  showMessageMin: boolean;
  disabled?: boolean;
  INPUT_DELAY?: number;
  getOptionDisabled?: (value: any) => boolean;
  onInput?: (e: string) => void;
}

export default function FilterAutoComplete(props: IFilterAutoComplete) {
  const [valueInput, setInputValue] = React.useState("");
  const [field, meta, helpers] = useField({ name: props.name } as any);

  const handleChange = (_: any, value: any[]) => {
    if (value != null) {
      props.multiple ? helpers.setValue(value) : helpers.setValue([value.length > 1 ? value[1] : value[0] ]);
    }
  };

  const onChangeInputValue = (e: any, value: string) => {
    setInputValue(value);
    debounceHandler(e, value);
  };

  const debounceHandler = useCallback(
    debounce((event, inputValue) => {
      onInputValue(event, inputValue);
    }, props.INPUT_DELAY ?? 500),
    []
  );

  const onInputValue = (e: any, value: string) => {
    if (props?.onInput && value != "undefined" && value != undefined) {
      props?.onInput(value);
    }
  };

  // temporal fixed {load information from url }
  if (props.name == "typeProperty" && typeof field.value[0] == "string") {
    helpers.setValue(
      props.data.filter((x: any) => field.value.includes(x["slug"]))
    );
  }

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: props.name,
    multiple: true,
    options: props.data,
    openOnFocus: true,
    disabled: props.disabled,
    onChange: handleChange,
    value: field.value,
    onInputChange: onChangeInputValue,
    inputValue: valueInput == "undefined" ? "" : valueInput,
    onClose: () => {
      setInputValue("");
    },
    getOptionLabel: (option) => option?.description,
  });

  const clearField = () => {
    helpers.setValue([]);
    if (props.onInput != undefined) {
      props.onInput("");
    }
  };

  const text = getInputProps().value as string;

  return (
    <Root>
      {valueInput == "undefined" + ""}
      <WrapperContentComponent {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          <Label {...getInputLabelProps()}>{props.label}</Label>
          <input
            {...getInputProps()}
            disabled={props.disabled}
            name={props.name}
            value={valueInput ?? ""}
          />
        </InputWrapper>
        <SearchIconBlue />
      </WrapperContentComponent>
      {text.length < 3 && focused && props.showMessageMin && (
        <MessageSearch>Escribe 3 o mas letras para buscar</MessageSearch>
      )}
      {field?.value != null &&
        "map" in field.value &&
        field.value.map((option: values, index: number) => (
          <StyledTag
            {...getTagProps({ index })}
            key={index}
            label={option?.description}
            onDelete={
              props.multiple ? getTagProps({ index }).onDelete : clearField
            }
          />
        ))}
      {groupedOptions?.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as typeof props.data).map((option, index) => (
            <>
              {!field?.value?.map((x: any) => x.id)?.includes(option.id) && (
                <li {...getOptionProps({ option, index })} key={index}>
                  <span>{option?.description}</span>
                </li>
              )}
              <Divider></Divider>
            </>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
}

const Root = styled("div")(
  ({ theme }) => `
    width:100%;
    display: block;
    padding:10px 0;
`
);

const WrapperContentComponent = styled("div")(
  ({ theme }) => `
    background:#fff;
    height:75px; 
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius:30px;
    overflow: hidden;
    padding:10px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom:5px;
`
);

const MessageSearch = styled("div")(
  ({ theme }) => `
  background-color:white;
  padding:10px;
  border-radius:8px;
  color:var(--color-blue-main);
  font-size:14px;
  font-weight:700; 
  width:97%;
  margin:auto;
`
);

const StyledTag = styled(Chip)(
  ({ theme }) => `
  background-color:var(--color-blue-main);
    color:white;
    margin:1px 2px;
  & svg {
    fill:white; 
  }
`
);

const Label = styled("label")`
  line-height: 1.5;
  display: block;
  color: var(--color-dark-gray);
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 15px;
  max-width: 160px;
  min-width: fit-content;
  padding: 0px 8px;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 100%;
  // max-width:300px;
  min-width:150px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  & input {
    background-color: #fff;
    color: var(--color-blue-main);
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    font-size: 16px;
    font-weight: 600;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
   width: 100%;
  margin: 2px 0 0;
  padding: 0 15px;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  & li {
    padding: 15px 12px;
    display: flex;


    & span {
      flex-grow: 1;
      font-size:14px;
      font-weight:600;
    color:gray;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    // background-color: ;
    color:var(--color-blue-main);
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

const SearchIconBlue = styled(SearchIcon)(
  ({ theme }) => `
   color: var(--color-dark-gray);
   font-size: 27px;
  `
);
