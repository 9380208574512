import React, { useCallback } from "react";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

interface IAccordion {
  expandIcon?: React.ReactNode;
  id: string;
  label: string | JSX.Element;
  children: JSX.Element;
  isActiveAccordion?: any;
}

export default function AccordionBase(props: IAccordion) {
  const [isExpanded, Expaded] = React.useState<boolean>(false);

  const handleToogleAccordion = useCallback(() => {
    Expaded(!isExpanded);
    if (props?.isActiveAccordion != undefined) {
      props?.isActiveAccordion(!isExpanded);
    }
  }, [isExpanded]);

  return (
    <Container>
      <AccordionCustom expanded={isExpanded}>
        {!isExpanded && <AccordionSummaryCustom
          expandIcon={props.expandIcon ?? false}
          aria-controls="panel1a-content"
          id={props.id}
        >
          <AccordionLabel onClick={handleToogleAccordion}>
            {props.label}
          </AccordionLabel>
        </AccordionSummaryCustom>}
        <AccordionDetails>{props.children}</AccordionDetails>
        <AccordionLabel onClick={handleToogleAccordion}>
          {props.label}
        </AccordionLabel>
      </AccordionCustom>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: inline-block;
`;

const AccordionCustom = styled(Accordion)`
  background: transparent;
  box-shadow: none;
  padding: 0px;
  & .MuiAccordionDetails-root {
    padding: 0px;
  }
`;

const AccordionLabel = styled(Typography)`
  display: block;
  text-align: center;
  color: var(--font-family);
  color: var(--color-blue-main);
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  `;

const AccordionSummaryCustom = styled(AccordionSummary)`
  margin:16px 0px;
  &.MuiAccordionSummary-root {
    min-height: 20px;
    height: 20px;
  }
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
