import { citiesEndpoints } from "shared/models/endpoits/cities.endpoint";
import { IBaseFilter } from "shared/models/interfaces/base-filter-list";
import { ICitiesResponse } from "shared/models/interfaces/response/cities.interface";
import { getRequest } from "./base-services.request";

export interface ICitiesListFilters extends IBaseFilter {
  q?: string;
  provinceId?: string;
  withSectors?: boolean;
  limit?: number;
}
export const getCities = (filters: ICitiesListFilters): Promise<ICitiesResponse> => {
  return getRequest(citiesEndpoints.citiesList, filters).then(res => res?.data);
};   
