enum RealestateStatic {
  /**
   * @member {string}
   * @method post
   * @version 2.0
   * @description Get realestates on platform
   */
  RealestateList = "/v2/realestates",

  /**
   * @member {string}
   * @method post
   * @version 2.0
   * @description Get realestates on platform
   */
  realestatesForSelect = "/v2/realestates/for-select",
}

const RealestateDynamic = {
  /**
   * @member {string}
   * @method GET
   * @version 2.0
   * @description Get realestate details
   */
  RealestateDetail: (RealestateId?: number) => `v2/realestates/${RealestateId}`,

    /**
   * @member {string}
   * @method GET
   * @version 2.0
   * @description Send realestate log
   */
    RealEstateLog: (RealestateId?: number | string) => `v2/realestates/${RealestateId}/log-activity`,
};

export const RealestateEndpoints = {
  ...RealestateStatic,
  ...RealestateDynamic,
};

export type RealestateEndpoints = typeof RealestateEndpoints;
