import styled from "@emotion/styled";
import { useField } from "formik";
import React from "react";
interface ISwitchLabels {
  options: { label: string; value: any }[];
  onChange: (value: any) => void;
  value: string;
}

export default function DefaultSwitchLabels(props: ISwitchLabels) {
  const { value, options, onChange } = props;
  return (
    <ContentSw className="content-switch-label">
      <div className={options[0].value == value ? "a" : "b"}></div>
      <span onClick={() => onChange(options[0].value)}>{options[0].label}</span>
      <span onClick={() => onChange(options[1].value)}>{options[1].label}</span>
    </ContentSw>
  );
}

const ContentSw = styled.div`
  width: 100%;
  background: gray;
  min-width: 150px;
  display: flex;
  justify-content: space-between;
  height: 40px;
  border-radius: 20px;
  color: white;
  position: relative;
  font-size: 15px;
  cursor: pointer;
  span {
    position: relative;
    border-radius: 20px;
    max-width: 50%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding: 5px;
    font-family: "Arial";
  }
  div {
    position: absolute;
    height: 100%;
    width: 50%;
    z-index: 1;
    border-radius: 20px;
    background: var(--color-blue-main);
    &.a {
      transform: translateX(0%);
      transition: all 0.8s ease;
    }
    &.b {
      transform: translateX(100%);
      transition: all 0.8s ease;
    }
  }
`;
