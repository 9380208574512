
enum citiesStatic {
  /**
   * @member {string}
   * @method post
   * @version 2.0
   * @description get cities
   */
  citiesList = "/v2/cities",
}

const citiesDynamic = {
  // /**
  //  * @member {string}
  //  * @method GET
  //  * @version 2.0
  //  * @description get cities details
  //  */
  // citiesDetail: (citiesID?: number) =>
  //   `/v1/cities/${citiesID}`,
};

export const citiesEndpoints = {
  ...citiesDynamic,
  ...citiesStatic,
};

export type citiesEndpoints = typeof citiesEndpoints;
