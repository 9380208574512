import styled from '@emotion/styled'
import React from 'react'
import Button from 'shared/components/Button'
import ButtonTypesField from 'shared/components/ButtonTypesField'
import SwitchLabels from 'shared/components/switch-labels/formik-switch-labels'
import TextField from 'shared/components/text-field'
import { Divider, Grid } from '@mui/material'
import { CCurrencyTypes } from 'shared/models/const/currencyTypes'
import { MoneyInput } from 'shared/components/mask-components'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { useIntlBusinessTypes } from 'shared/models/const/businessTypes'
import { useIntlPropertyCollectionTypes, useIntlPropertyTypes } from 'shared/models/const/property-types'
import { css } from '@emotion/react'
import CheckBoxGroupV2 from 'shared/components/checkbox-group-v2'
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ImprovementsList from '../../../improvements-list'
import FilterAutoComplete from './auto-complete-field'
import CitiesField from './cities-field'
import FormCodeSearch from './code-search-field'
import SectorsField from './sector-field'
import { useField } from 'formik'

export const MobileForm = (props: any) => {
  const router = useRouter();
  const t = useTranslations("buy-sell-filter");
  const bussineTypes = useIntlBusinessTypes();
  const CPropertyTypes = useIntlPropertyTypes();
  const CPropertyCollection = useIntlPropertyCollectionTypes();
  const translations = useTranslations("formRealestateFilters");
  const [{ value: amountMin }] = useField("amountMin");
  const [{ value: amountMax }] = useField("amountMax");

  const propertyList = (
    router.pathname.includes("/collection")
      ? CPropertyCollection
      : CPropertyTypes
  ).map((x) => ({
    ...x,
    description: x.name,
    id: x.id,
  }));

  const handleSubmit = () => {
    props.handleToggleModal();
    setTimeout(()=>{
      props.handleSubmit();
    }, 500)
  };

  const handleReset = () => {
    props.handleToggleModal();
    setTimeout(()=>{
      props.handleReset();
    }, 500)
  };

  return (
    <Grid container>
      <ContainerButton item xs={12} bgcolor={"#EFEFF1"} sx={{ py: 2 }}>
        <FilterButtonOutline onClick={handleReset}>
          {translations("delete")}
        </FilterButtonOutline>
        <FilterButton disabled={amountMin && amountMax && (+amountMax < +amountMin)} onClick={handleSubmit}>
          {translations("apply")}
        </FilterButton>
      </ContainerButton>
      <Grid
        container
        justifyItems={"center"}
        alignItems="center"
        sx={{ mt: 3 }}
      >
        <Grid item xs={4}>
          <Label>{t("shop-sell-short")}</Label>
        </Grid>
        <Grid item xs={8}>
          <SwitchLabels options={bussineTypes} name="businessTypes" />
        </Grid>
      </Grid>
      <Grid item xs sx={{ mt: 3 }}>
        <CheckBoxGroupV2
          column={2}
          options={[
            {
              label: "Construida",
              value: true,
            },
            {
              label: "En construcción",
              value: false,
              textWrap: true,
            },
          ]}
          name="onlyUnits"
          checkBoxProps={{
            icon: <RadioButtonUncheckedIcon sx={{ color: "gray" }} />,
            checkedIcon: <RadioButtonCheckedIcon sx={{ color: "#39b44a" }} />,
          }}
        />
      </Grid>
      <Grid item xs sx={{ mt: 3 }}>
        <CheckBoxGroupV2
          column={2}
          options={[
            {
              label: translations("furnished"),
              value: 1,
            },
            {
              label: translations("whiteLine"),
              value: 2,
            },
          ]}
          multiple={false}
          name="extraBusinessTypes"
          checkBoxProps={{
            icon: <RadioButtonUncheckedIcon sx={{ color: "gray" }} />,
            checkedIcon: <RadioButtonCheckedIcon sx={{ color: "#39b44a" }} />,
          }}
        />
      </Grid>

      <Grid item xs={12} sx={{ mt: 3 }}>
        <FilterAutoComplete
          id="typeProperty"
          showMessageMin={false}
          name="typeProperty"
          data={propertyList}
          multiple={true}
          label={t("youLookingForMobile")}
        ></FilterAutoComplete>
      </Grid>

      <Grid item xs={12} sx={{ mt: 3 }}>
        <CitiesField></CitiesField>
      </Grid>

      <Grid item xs={12} sx={{ mt: 3 }}>
        <SectorsField></SectorsField>
      </Grid>

      <FormCodeSearch />

      <Grid item xs={12}>
        <Divider></Divider>
      </Grid>

      <Grid
        container
        justifyItems={"center"}
        alignItems="center"
        sx={{ mt: 3 }}
      >
        <Grid item xs={4}>
          <Label>{translations("currency")}</Label>
        </Grid>
        <Grid item xs={8}>
          <SwitchLabels options={CCurrencyTypes} name="currencyType" />
        </Grid>
      </Grid>

      <Grid container sx={{ paddingY: 2 }} alignItems={"center"} spacing={1}>
        {amountMin && amountMax && (+amountMax < +amountMin) ? (
          <PriceRangeErrorMessage>
            {translations("price_range-error_message")}
          </PriceRangeErrorMessage>
        ) : null}
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TextFieldMoney
            placeholder={translations("minimum")}
            inputComponent={MoneyInput}
            name="amountMin"
          />
        </Grid>

        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TextFieldMoney
            inputComponent={MoneyInput}
            placeholder={translations("maximum")}
            name="amountMax"
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ButtonTypesField label={translations("baths")} name="bathrooms" />
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <ButtonTypesField label={translations("bedrooms")} name="bedrooms" />
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <ButtonTypesField label={translations("parkings")} name="parkings" />
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <ButtonTypesField label={translations("level")} name="floorLevels" />
      </Grid>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <Divider></Divider>
      </Grid>

      <ImprovementsList columns={2} />
      <ContainerButtonFooter container>
        <FilterButton sx={{ m: "auto" }} onClick={handleSubmit}>
          {translations("apply")}
        </FilterButton>
      </ContainerButtonFooter>
    </Grid>
  );
};

const TextFieldCustomShared = css`
  background-color: white;
  padding: 20px;
  border-radius: 30px;
  border: 0px solid transparent;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  & input::placeholder {
    color: gray;
  }
  &* {
    outline: none;
  }
`;

const TextFieldMoney = styled(TextField)`
  ${TextFieldCustomShared}
`;

const FilterButton = styled(Button)`
  padding: 7px;
  min-width: 45%;
`;

const FilterButtonOutline = styled(Button)`
  padding: 7px;
  min-width: 45%;
  background-color: transparent;

  border: 1px solid gray;
  color: gray;
  :hover,
  :focus {
    background: transparent;
  }
`;

const Label = styled.label`
  font-size: 14px;
  color: var(--color-blue-main);
  font-weight: 600;
`;

const ContainerButtonFooter = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
`;

const ContainerButton = styled(Grid)`
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  z-index: 2;
`;

const PriceRangeErrorMessage = styled.p`
  color: var(--color-red-error);
  font-weight: bold;
  margin-left: 10px;
`;