import { useTranslations } from 'next-intl'

export function useIntlBusinessTypes() {
  const translation = useTranslations("formRealestateFilters");

  const CBusinessTypes = [
    { label: translation("sale"), value: "sale" },
    { label: translation("lease"), value: "lease" },
  ];

  return CBusinessTypes;
}

export const CBusinessTypes = [
  { label: "Venta", value: "sale" },
  { label: "Alquiler", value: "lease" },
];
