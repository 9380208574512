import { FormControl, FormControlLabel } from "@mui/material";
import { Radio, RadioGroup } from "@mui/material";
import { useField } from "formik";
import { css } from "@emotion/react";
import React from "react";

const RadioButtonGroup = (props: any) => {
  const {
    name,
    items,
    useFormik = true,
    values = null,
    onChange,
    ...rest
  } = props;

  if (useFormik) {
    return React.createElement(RadioButtonGroupFormik, {
      name,
      items,
      ...rest,
    });
  } else {
    return React.createElement(RadioButtonGroupNoFormik, {
      value: values,
      onChange,
      items,
      ...rest,
    });
  }
};

export default RadioButtonGroup;

const RadioButtonGroupFormik = (props: any) => {
  const { name, items, ...rest } = props;
  const [field, meta, helpers] = useField(name);
  return (
    <FormControl css={Styles.root} {...rest}>
      <RadioGroup row {...field}>
        {items.map((item: any, i: number) => (
          <FormControlLabel
            className={field.value == item.value ? "active" : ""}
            key={i}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const RadioButtonGroupNoFormik = (props: any) => {
  const { items, value, onChange, ...rest } = props;
  return (
    <FormControl css={Styles.root} {...rest}>
      <RadioGroup row onChange={onChange}>
        {items.map((item: any, i: number) => (
          <FormControlLabel
            className={value == item.value ? "active" : ""}
            key={i}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const Styles = {
  root: css({
    "& .MuiFormGroup-root": {
      display: "flex",
      flexWrap: "nowrap",
    },
    "& .MuiFormControlLabel-root": {
      backgroundColor: "var(--color-gray-02)",
      color: "white",
      padding: 10,
      minWidth: 85,
      borderRadius: "25px",
      "&.active": {
        backgroundColor: "var(--color-blue-main)",
      },
      "& .MuiRadio-root": {
        display: "none",
        textAlign: "center",
      },
      "& .MuiFormControlLabel-label": {
        width: "100%",
        textAlign: "center",
      },
    },
  }),
};
