
enum SectorsStatic {
  /**
   * @member {string}
   * @method post
   * @version 2.0
   * @description get Sectors
   */
  SectorsList = "/v2/sectors",
}

const SectorsDynamic = {
  // /**
  //  * @member {string}
  //  * @method GET
  //  * @version 2.0
  //  * @description get Sectors details
  //  */
  // SectorsDetail: (SectorsID?: number) =>
  //   `/v1/Sectors/${SectorsID}`,
};

export const SectorsEndpoints = {
  ...SectorsDynamic,
  ...SectorsStatic,
};

export type SectorsEndpoints = typeof SectorsEndpoints;
